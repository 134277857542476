(function () {
  'use strict';
  angular
    .module('app')
    .component('home', {
      templateUrl: 'app/components/home/home.html',
      controller: HomeController
    });

  HomeController.$inject = ['user', '$uibModal', 'globalService', '_', '$rootScope', 'ActionCableChannel', '$timeout'];

  function HomeController($user, $uibModal, $globalService, _, $rootScope, ActionCableChannel, $timeout) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function () {
      $rootScope.$emit('uploadBreadCrum', { current: '', before: 'Home' });

      $globalService.getNumbersByIndex(1)
        .then(function (res) {
          vm.data = res;
          vm.currentPage = vm.data.currentPage;
          vm.send = false;
        })

      $globalService.getNumbersCounts('counts')
        .then(function (res) {
          vm.counts = res;
        })
    }

    vm.call_number = function (data) {
      $globalService.numbers.get({ id: data.id }, data, function (res) {
        openModal(data)
      }, function (err) {
        Swal('Info', err.data.message, 'info');
      })

    }

    function openModal(data) {
      var modal = $uibModal.open({
        animation: true,
        component: 'numberCalling',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          number: function () {
            return data;
          }
        }
      });
    }


    vm.pageChanged = function () {
      vm.send = true;
      vm.currentPage = vm.data.currentPage;
      $globalService.getNumbersByIndex(vm.currentPage)
        .then(function (res) {
          vm.send = false;
          vm.data.data = res.data;
        })
    };


    var channel = new ActionCableChannel("DashboardChannel", {});
    var callback = function (message) {
      
      var object = JSON.parse(message.data)
      var number = _.find(vm.data.data, { id: object.id })
      switch (message.type) {
        case "call_in_progress":
          if(number){
            setCallInPorgress(number, object)
          }
        break;
        case "call_end":
          if(number){
            setFinishCall(number, object);
          }
        break;
        case 'update_counts':
          vm.counts = object;
        break
      
        default:
          break;
      }
      
      
      $timeout(function () {
        number.class = null;
      }, 1000)

    };
    channel.subscribe(callback)
    .then(function () {
      console.log('Conectado al canal')
      vm.sendToMyChannel = function (message) {
        channel.send(message);
      };
    });

    function setCallInPorgress(number, object){
      number.class = "numberNotification";
      number.status = object.status;
      number.pretty_status = object.pretty_status;  
    }

    function setFinishCall(number, data){
      number.class = "callEndNotification";
      number.address = data.address;
      number.city = data.city;
      number.company = data.company;
      number.database = data.database;
      number.email = data.email;
      number.first_name = data.first_name;
      number.id = data.id;
      number.lang = data.lang;
      number.last_name = data.last_name;
      number.number_database_id = data.number_database_id;
      number.phone = data.phone;
      number.pretty_lang = data.pretty_lang;
      number.pretty_status = data.pretty_status;
      number.status = data.status;
      number.tags = data.tags;
      number.zip = data.zip;
    }

  }
})();
