(function(){
  'use strict';
  angular
  .module('app')
  .component('databases', {
    templateUrl: 'app/components/databases/databases.html',
    controller: DatabasesController
  });

  DatabasesController.$inject = ['$uibModal','user', '$timeout', '$interval', '_','globalService','$rootScope'];

  function DatabasesController($uibModal, $user, $timeout, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.add = add;
    vm.update = update;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Database Admin', before: 'Home'});
      vm.databases = $globalService.dataBases.query();
    }


    function add(){

      var modal = $uibModal.open({
        animation: true,
        component: 'databaseModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          new_file: function(){
            return true
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      });
    }

    function update(database){
      $globalService.dataBases.update({id: database.id}, database, function(res){
        vm.databases = res.databases;
        Swal('Info',res.message,'success');
      }, function(err){
        Swal('Error',err.data.message,'error');
      })
    }

    vm.destroyDatabase = function(database, index){
      swal({
        title: 'Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.dataBases.delete({id: database.id}, function(res){
              vm.databases.splice(index, 1)
              resolve(res.message);
            }, function(err){
              Swal('Error',err.data.message,'error');
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        if(data.value){
          Swal('Info',data.value,'info');
        }
      });
      
    }


    $rootScope.$on('loadDatabases',function(evt, data){
      vm.$onInit();
    })
    
  }


})();
