(function () {
  'use strict';
  angular
    .module('app')
    .run(actionCable)
    .run(run);

  run.$inject = ['$rootScope', '$state', 'authService', '$document', '$transitions', 'user', '$window'];

  actionCable.$inject = ['ActionCableConfig','BASE_URL','user'];

  function actionCable(ActionCableConfig,BASE_URL, $user) {
    var current_user = $user.getCurrentUser();
    
    if(current_user != undefined && current_user.hasOwnProperty('token')){
      var token = current_user.token;
      if(BASE_URL.split('/')[2].split(':')[0] == 'localhost'){
        ActionCableConfig.wsUri = "ws://"+BASE_URL.split('/')[2]+"/cable?token="+token;
      }else{
        ActionCableConfig.wsUri = "wss://"+BASE_URL.split('/')[2]+"/cable?token="+token;
      }
        ActionCableConfig.autoStart = true;
      // // ActionCableConfig.wsUri = "ws://104.248.14.57:3000/cable";
      // ActionCableConfig.wsUri = "ws://104.248.14.57:3000/cable";
      // ActionCableConfig.autoStart = true;
    }
  }


  function run($rootScope, $state, $authService, $document, $transitions, $user, $window) {
    $transitions.onSuccess({}, function () {
      $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
    });

    $transitions.onError({}, function (trans) {
      switch (trans.error().detail) {
        case 'ALREADY_LOGGED_IN':
          $state.go('home');
          break;
        case 'UNAUTHORIZED':
          console.log('UNAUTHORIZED')
          unauthorizedHandler();
          break;
        default:
          $state.go('404');
      }
    });

    $rootScope.$on('UNAUTHORIZED', unauthorizedHandler);

    function unauthorizedHandler() {
      if ($authService.logout()) {
        $state.go('login');
      }
    }

    var version = '0.0.2';

    // if($window.localStorage.hasOwnProperty('system_version')){
    //   var current = $window.localStorage.getItem('version');
    //   if(current != version){
    //     $window.localStorage.setItem('system_version', version);
    //     $window.location.reload(true);
    //   }
    // }else{
    //   $window.localStorage.setItem('system_version', version);
    //   $window.location.reload(true);
    // }
  }
})();
