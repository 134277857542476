(function () {
  'use strict';
  angular
  .module('app')
  .factory('authService', authService);

  authService.$inject = ['$http', 'url', 'user', '$window', '$state'];

  function authService($http, url, $user, $window, $state) {

    function doLogin(user) {
      return $http.post(url.getAuthUrl(), user)
      .then(function(res){
        return res.data;
      })
    }

    function unlockScreen(data){
      return $http.post(url.getUnlockUrl(), data)
      .then(function(res){
        return res.data;
      })
    }

    function createUser(data){
      return $http.post(url.getApiUrl('/usuarios'), data)
      .then(function(res){
        return res.data;
      })
    }
    function destroyUser(id){
      return $http.delete(url.getApiUrl('/usuarios/'+id))
      .then(function(res){
        return res.data;
      })
    }


    function logout() {
      $window.localStorage.removeItem('user')
      if($window.localStorage.removeItem('user') == undefined){
        return true;
      }else{
        return false;
      }
    }

    return {
      doLogin: doLogin,
      logout: logout,
      createUser: createUser,
      destroyUser: destroyUser,
      unlockScreen: unlockScreen
    };
  }

})();
