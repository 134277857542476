angular
  .module('app')
  .filter('imageUrl', imageUrl);

function imageUrl(url) {

  return function (relativePath, defaultPath) {

    // transform the item and return it
    if (relativePath === null) {// cargando o image-null
      return defaultPath || '/images/image_placeholder.jpg';
    }
    // if (relativePath[0] === 'h') {// si comienza por http es absoluta
    //   return relativePath || (url.getBaseUrl() + relativePath);
    // }
    return url.getBaseUrl() + relativePath;
  };
}
