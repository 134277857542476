(function(){
  'use strict';
  angular
  .module('app')
  .component('asideBar', {
    templateUrl: 'app/components/aside-bar/aside-bar.html',
    controller: AsideBarController
  });

  AsideBarController.$inject = ['user','$state'];

  function AsideBarController($user, $state) {
    var vm = this;

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
    }

    vm.goTo = function(){
      switch (vm.currentUser.pretty_type) {
        case "Manager":
        $state.go('dashboardManager');
          break;
        case "Administrator":
          $state.go('home');
          break;
        case "Agente":
          $state.go('dashboardAgent');
          break
        default:
          $state.go('home');
      }
    }

  }

})();
