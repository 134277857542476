(function () {
  'use strict';
  angular
    .module('app')
    .directive('viewLogin', viewLogin);

  viewLogin.$inject = ['$state'];

  function viewLogin($state) {
    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      if($state.current.name == 'login'){
        $('body').removeClass('skin-blue sidebar-mini')
        $('body').addClass('hold-transition login-page')
        console.log($('body').addClass('hold-transition login-page'))
      }
    }
  }
})();
