(function () {
  'use strict';
  angular
  .module('app')
  .component('databaseModal', {
    templateUrl: 'app/components/databases/data_modal.html',
    controller: DatabaseModalController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });


  DatabaseModalController.$inject = ['url','user', 'Upload', '$interval', '_','globalService','$rootScope'];

  function DatabaseModalController($url, $user, Upload, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.currentUser = $user.getCurrentUser();
    vm.new_file = true;
    
    vm.$onInit = function(){
      vm.new_file = vm.resolve.new_file;
    }
    function save(){
      console.log(vm.database.file)
      console.log(Upload.isFile(vm.database.file))

      if(vm.new_file){
        if(Upload.isFile(vm.database.file)){
          vm.database.file.upload = Upload.upload({
            url: $url.getApiUrl('number_databases'),
            data: vm.database,
          })
          vm.database.file.upload.then(function(res){
            vm.result = res.data.data
            vm.send = false;
            $rootScope.$emit('loadDatabases',{});
            Swal('Info',res.data.message,'info');
          }, function(err){
            vm.result = err.data.data;
            vm.send = false;
            Swal('Info',err.data.message,'info');
          }, function (evt) {
            vm.database.file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
          });
        
        }else{
          Swal('Info','Not a file','warning');
        }
      }else{
        if(Upload.isFile(vm.database.check_file)){
          vm.database.check_file.upload = Upload.upload({
            url: $url.getApiUrl('number_databases/upload_check_file'),
            data: vm.database,
          })
          vm.database.check_file.upload.then(function(res){
            vm.result = res.data.data
            vm.send = false;
            $rootScope.$emit('loadDatabases',{});
            Swal('Info',res.data.message,'info');
          }, function(err){
            vm.result = err.data.data;
            vm.send = false;
            Swal('Info',err.data.message,'info');
          }, function (evt) {
            vm.database.check_file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
          });
        
        }else{
          Swal('Info','Not a file','warning');
        }
      }
    }
  }


})();


