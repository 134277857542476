(function() {
  'use strict';
  angular
    .module('app')
    .factory('url', url);

  url.$inject = ['BASE_URL','DOMAIN_URL'];

  function url(BASE_URL,DOMAIN_URL) {
    return {
      getApiUrl: getApiUrl,
      getBaseUrl: getBaseUrl,
      getAuthUrl: getAuthUrl,
      getUnlockUrl: getUnlockUrl,
      geturlReportPdf: geturlReportPdf,
      geturlReportPdfWithoutParams: geturlReportPdfWithoutParams
    };

    function getApiUrl(resource) {
      return BASE_URL + '/api/callcenter/v1/' + resource;
    }

    function getAuthUrl(){
      return BASE_URL + '/api/callcenter/v1/login';
    }
    function getUnlockUrl(){
      return BASE_URL + '/api/v1/login/unlock_screen';
    }

    function getBaseUrl() {
      return BASE_URL;
    }

    function geturlReportPdf(resource, data){
      return BASE_URL + '/api/callcenter/v1/reports/'+resource+'.pdf'+data;
    }
    function geturlReportPdfWithoutParams(resource){
      return BASE_URL + '/api/callcenter/v1/reports/'+resource+'.pdf';
    }
  }
})();
