(function () {
  'use strict';
  angular
  .module('app')
  .component('numberCalling', {
    templateUrl: 'app/components/home/modalCall.html',
    controller: ModalCallController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });


  ModalCallController.$inject = ['url','user', 'Upload', '$interval', '_','globalService','$rootScope'];

  function ModalCallController($url, $user, Upload, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    
    
    vm.$onInit = function(){
      vm.statuses = $globalService.getStatusOfNumbers();
      vm.number = vm.resolve.number;
      vm.statusSelected = _.find(vm.statuses, {id: vm.number.status}); 
    }


    vm.update = function(){
      $globalService.numbers.update({ id: vm.number.id }, vm.number, function (res) {
        vm.close({$value: {}})
      }, function (err) {
        Swal('Info', err.data.message, 'info');
      })
    }



  }


})();


