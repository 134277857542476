(function () {
  'use strict';
  angular
    .module('app')
    .factory('globalService', globalService);

  globalService.$inject = ['$http', 'url', 'user', '$window', '$state', '$resource'];

  function globalService($http, url, $user, $window, $state, $resource) {
    function hashToParams(params) {
      var params_url = '';

      Object.keys(params).forEach(function (item, index) {
        if (index == 0 && params[item] != null) {
          params_url += '?' + item + '=' + params[item];
        } else if (index != 0 && params[item] != null) {
          params_url += '&' + item + '=' + params[item];
        }
      });

      return params_url;
    }

    var users = $resource(url.getApiUrl('users/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var dataBases = $resource(url.getApiUrl('number_databases/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var numbers = $resource(url.getApiUrl('numbers/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var sections = $resource(url.getApiUrl('sections/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });
    var salesReport = $resource(url.getApiUrl('check_databases_sales/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });

    function salesReportExcel(data) {
      return url.getApiUrl('check_databases_sales/'+data.id+'.xlsx?token='+data.token)
    }

    //Miscelaneos
    function getUserTypes() {
      return $http.get(url.getApiUrl('miscelaneos/user_types'))
        .then(function (res) {
          return res.data;
        })
    }
    function blockUser(data) {
      return $http.post(url.getApiUrl('users/block_user'), data)
        .then(function (res) {
          return res.data;
        })
    }
    function getAlloperators(){
      return $http.get(url.getApiUrl('users/operators'))
        .then(function (res) {
          return res.data;
        })
    }
    

    function reportPdf(resource, data) {
      if(data){
        return url.geturlReportPdf(resource, hashToParams(data));
      }else{
        return url.geturlReportPdfWithoutParams(resource);
      }
    }

    function setLocalCA(data) {
      var key = "customer_app_" + data.id;
      $window.localStorage.setItem(key, JSON.stringify(data));
      return true;
    }

    function getLocalCA(key) {
      var data = $window.localStorage.getItem(key);
      if (data != null) {
        return JSON.parse(data);
      }
    }

    function getNumbersByIndex(page){
      return $http.get(url.getApiUrl('numbers.json?page='+page))
      .then(function(res){
        return res.data;
      })
    }

    function getNumbersCounts(){
      return $http.get(url.getApiUrl('numbers/counts.json'))
      .then(function(res){
        return res.data;
      })
    }

    function reportNumbers(data){
      return $http.get(url.getApiUrl('reports/numbers_users.json'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function reportDatabases(){
      return $http.get(url.getApiUrl('reports/report_numbers_copy'))
      .then(function(res){
        return res.data;
      })
    }
    function reportDatabasesCheck(){
      return $http.get(url.getApiUrl('reports/databases_check_report'))
      .then(function(res){
        return res.data;
      })
    }
    function reportDatabasesCheckDestroy(id){
      return $http.delete(url.getApiUrl('number_databases/destroy_database_check?id='+id))
      .then(function(res){
        return res.data;
      })
    }
    function reportDatabasesDetails(id){
      return $http.get(url.getApiUrl('reports/report_numbers_copy_details.json?number_database_id='+id))
      .then(function(res){
        return res.data;
      })
    }

    

    function getStatusOfNumbers(){
      return [
        {id:0, label: 'Not Called'},
        {id:1, label: 'Calling in Progress'},
        {id:2, label: 'Voicemail'},
        {id:3, label: 'Disconnect'},
        {id:5, label: 'Medical coverage'},
        {id:6, label: 'Successful application'},
        {id:7, label: "Don't answer call"},
        {id:9, label: "Others"}
      ]
    }

    function getNumbersDatabse(data){
      return $http.get(url.getApiUrl('numbers.json'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }



    return {
      users: users,
      sections: sections,
      getUserTypes: getUserTypes,
      blockUser: blockUser,
      reportPdf: reportPdf,
      setLocalCA: setLocalCA,
      getLocalCA: getLocalCA,
      dataBases: dataBases,
      getNumbersByIndex: getNumbersByIndex,
      getNumbersCounts: getNumbersCounts,
      numbers: numbers,
      getStatusOfNumbers: getStatusOfNumbers,
      reportNumbers: reportNumbers,
      getAlloperators:getAlloperators,
      reportDatabases: reportDatabases,
      getNumbersDatabse: getNumbersDatabse,
      reportDatabasesDetails: reportDatabasesDetails,
      reportDatabasesCheck: reportDatabasesCheck,
      reportDatabasesCheckDestroy: reportDatabasesCheckDestroy,
      salesReport: salesReport,
      salesReportExcel: salesReportExcel
    };
  }

})();
