(function(){
  'use strict';
  angular
  .module('app')
  .directive('checkInactivityTime', checkInactivityTime);

  checkInactivityTime.$inject = ['toastr','$state'];

  function checkInactivityTime(toastr, $state){
    return {
      link: link
    };


    function link(scope, element, attrs) {
      $(element).ready(function(){
        if($state.current.name != 'blockScreen' || $state.current.name != 'login'){
          var idleTime = 0;
          $(document).ready(function () {
            //Increment the idle time counter every minute.
            var idleInterval = setInterval(timerIncrement, 60000); // 1 minute

            //Zero the idle timer on mouse movement.
            $(this).mousemove(function (e) {
              idleTime = 0;
            });
            $(this).keypress(function (e) {
              idleTime = 0;
            });
          });

          function timerIncrement() {
            idleTime = idleTime + 5;
            if (idleTime > 59) { // 20 minutes
              $state.go('blockScreen', {current_page: $state.current.name})
            }
          }
        }
      })
    }
  }

})();
