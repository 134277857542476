(function(){
  'use strict';
  angular
  .module('app')
  .component('users', {
    templateUrl: 'app/components/users/users.html',
    controller: UserController
  });

  UserController.$inject = ['$uibModal','toastr', '$timeout', '$interval', '_','globalService','$rootScope'];

  function UserController($uibModal, toastr, $timeout, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.addUser = addUser;
    vm.edit = edit;
    vm.blockUser = blockUser;


    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Administracion de Usuarios', before: 'Home'});
      vm.sections = $globalService.sections.query();
      vm.users = $globalService.users.query();
      $globalService.getUserTypes()
      .then(function(res){
        vm.user_types = res;
      })

    }


    function addUser(){

      var modal = $uibModal.open({
        animation: true,
        component: 'userModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          sections: function(){
            return vm.sections;
          },
          user_types: function(){
            return vm.user_types;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      });
    }

    function edit(data){

      var modal = $uibModal.open({
        animation: true,
        component: 'userModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          sections: function(){
            return vm.sections;
          },
          user_types: function(){
            return vm.user_types;
          },
          user: function(){
            return angular.copy(data);
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      });
    }

    function blockUser(user, index) {
      swal({
        title: '¿Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.blockUser({id: user.id})
            .then(function(res) {
              swal('Exito',res.message,'success');
              vm.$onInit();
              // resolve();
            }, function() {
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {});
  }






  }


})();
