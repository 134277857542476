(function () {
  'use strict';
  angular
  .module('app')
  .component('showDetails', {
    templateUrl: 'app/components/applications/showDetails.html',
    controller: ShowDetailsModalController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ShowDetailsModalController.$inject = ['$sce','globalService','user'];

  function ShowDetailsModalController($sce, $globalService, $user) {
    var vm = this;
    vm.application = {};
    vm.showOthers = false;
    vm.confirm = confirm;
    vm.currentUser = $user.getCurrentUser();


    vm.$onInit = function() {
      vm.type_insurance_companies = vm.resolve.type_insurance_companies;
      vm.applicationsType = vm.resolve.applicationsType;
      vm.allCompanies = vm.resolve.allCompanies;
      if(vm.resolve.application.id){
        vm.application = vm.resolve.application;
        vm.companySelected = _.find(vm.allCompanies, {id: vm.application.insurance_data.company.id});
        vm.productSelected = _.find(vm.companySelected.products, {id: vm.application.insurance_data.company_product.id});
        vm.typePlanSelected = _.find(vm.productSelected.company_product_type, {id: vm.application.insurance_data.company_product_type.id});
        vm.planSelected = _.find(vm.typePlanSelected.company_product_plans, {id: vm.application.insurance_data.company_product_plan.id});
        vm.applicationTypeSelected = _.find(vm.applicationsType, {id: parseInt(vm.application.application_type)});
      }
      if(vm.allCompanies){
        vm.allCompanies.push({
          name: 'Others',
          id: 0
        })
      }
      setEfectyDate();
    }


    function confirm(){
      vm.close({$value: vm.application});
    }

    vm.calculatePrima = function(){
      if(parseFloat(vm.application.subsidy) > parseFloat(vm.application.cost)){
        vm.application.beforehand = 0;
      }else{
        vm.application.beforehand = parseFloat(vm.application.cost) - parseFloat(vm.application.subsidy);
      }
    }

    vm.selectCurrentPlan = function(){
      vm.application.current_plan = vm.currentPlanSelected.name;
      if(vm.currentPlanSelected.id == 0){
        vm.showOthers = true;
      }

    }

    function setEfectyDate(){
      var date = moment();
      if(vm.application.id){
      }else{
        if(parseInt(date.format('DD')) < 25){
          vm.application.efecty_date = date.add(1, 'M').startOf('month').format('MM-DD-YYYY');
        }else{
          vm.application.efecty_date = date.add(2, 'M').startOf('month').format('MM-DD-YYYY');
        }
      }
    }

    vm.hide = function(){
      vm.dismiss({$value: 'Dismiss'});
    }

  }

})();
