(function () {
    'use strict';
    angular
      .module('app')
      .directive('birthdayValidation', birthdayValidation);
  
    birthdayValidation.$inject = [];
  
    function birthdayValidation() {
      return {
        require: '?ngModel',
        scope: {
          birthdayValidation: '='
        },
        link: link
      };
  
      function link(scope, el, attrs, ctrl) {
        if (!ctrl) {
          return;
        }

        var birthday = scope.birthdayValidation;
        scope.$watch('birthdayValidation', function (newValue) {
          birthday = newValue;
          ctrl.$validate();
        });

        ctrl.$validators.birthdayValidation = function(value){
            var date = moment(value)
            var majority = 18
            var diff = moment().diff(date,'years');
            return diff >= majority && diff < 100;
        };
      }
    }
  })();
  