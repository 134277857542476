(function(){
  'use strict';
  angular
  .module('app')
  .component('auth', {
    templateUrl: 'app/components/auth/auth.html',
    controller: AuthController
  });

  AuthController.$inject = ['$window', '$state','user', 'authService'];

  function AuthController($window, $state, $user, $authService) {
    var vm = this;
    vm.send = false;
    vm.doLogin = doLogin;

    vm.$onInit = function(){
      var classPage = angular.element(document.querySelector('#classPage'));
      if(classPage.hasClass('register-page')){
        classPage.removeClass('register-page');
        classPage.addClass('login-page');
      }
    }


    function doLogin() {
      vm.send = true;
      console.log(vm.user)
      $authService.doLogin(vm.user)
      .then(function(res){
        vm.send = false;
        var user  = res.user;
        $user.setCurrentUser(user);
        
        $window.location = '/';
        
      }, function(err){
        vm.send = false;
        vm.user = {};
        Swal('Error',err.data.message,'error');
      })
    }
  }
})();
