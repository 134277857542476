(function () {
  'use strict';
  angular
    .module('app')
    .directive('loadTree', loadTree);

  loadTree.$inject = ['$state','$timeout'];

  function loadTree($state, $timeout) {


    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      console.log('asd')
      $(el).click(function(){
        console.log()
        var a = $(el).find('a.nav-lick');
        a.toggleClass('collapsed');
        if(a.hasClass('collapsed')){
          var div = $(el).find('div.collapse')
          // div.toggleClass('show')
          div.slideDown("slow")
        }else{}
        var div = $(el).find('div.collapse')
        // div.toggleClass('show')
        div.slideUp("slow")
      });
    }
  }
})();
