(function(){
  'use strict';
  angular
  .module('app')
  .component('webHeader', {
    templateUrl: 'app/components/web-header/web-header.html',
    controller: WebHeaderController
  });

  WebHeaderController.$inject = ['user','authService','$state','$rootScope'];

  function WebHeaderController($user, $authService, $state, $rootScope) {
    var vm = this;
    vm.doLogout = doLogout;
    vm.bread_crum = {};

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();

    }

    function doLogout() {
      if($authService.logout()){
        $state.go('login')
      }
    }

    $rootScope.$on('uploadBreadCrum', function(e,d){
      vm.bread_crum.curren_page = d.current
    })
  }

})();
