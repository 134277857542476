(function () {
  'use strict';
  angular
    .module('app')
    .component('databaseReports', {
      templateUrl: 'app/components/database-reports/database-reports.html',
      controller: DatabaseReportsController
    });

  DatabaseReportsController.$inject = ['user', '$rootScope', '$interval', '_', 'globalService', '$uibModal', 'url'];

  function DatabaseReportsController($user, $rootScope, $interval, _, $globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.loadingData = false;
    vm.date = new Date();

    vm.$onInit = function () {
      $rootScope.$emit('uploadBreadCrum', { current: 'Reports Applications', before: 'Home' });
      $globalService.reportDatabases()
        .then(function (res) {
          vm.databases = res;
        })

    }

    vm.showNumbers = function (database) {
      vm.loadingData = true;
      $globalService.reportDatabasesDetails(database.id)
      .then(function(res){
        vm.currentDatabase = res;
        vm.loadingData = false;
      })
    }

    vm.download = function (data) {
      console.log(data)
      var new_data = _.reduce(data.copys, function (memo, number, i) {
        memo.push({
          "#": i + 1,
          "Database": data.description,
          "Number": number.phone,
          "Repeat With": number.database
        })
        return memo;
      }, [])
      var csv = Papa.unparse(new_data);

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, 'download.csv');
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'download.csv');
      tempLink.click();
    }

    vm.downloadNumbers = function (data, database) {
      var new_data = _.reduce(data, function (memo, number, i) {
        memo.push({
          "Number": number.phone,
          "Titular": number.first_name,
          "Gestion": number.gestion
        })
        return memo;
      }, [])
      var csv = Papa.unparse(new_data);

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, database.description+'.csv');
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', database.description+'.csv');
      tempLink.click();
    }



    vm.downloadNumbersDatabase = function(database) {
      $globalService.getNumbersDatabse({all: true, number_database_id: database.id})
      .then(function (res) {
        vm.downloadNumbers(res, database)
      })
    }
  }


})();
