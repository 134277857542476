(function(){
  'use strict';
  angular
  .module('app')
  .component('checkSalesReport', {
    templateUrl: 'app/components/salesReport/salesReport.html',
    controller: SalesReportController
  });

  SalesReportController.$inject = ['$uibModal','user', '$timeout', '$interval', '_','globalService','$rootScope'];

  function SalesReportController($uibModal, $user, $timeout, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.add = add;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Check Sales Databases', before: 'Home'});
      vm.loading = true;
      $globalService.salesReport.query().$promise
      .then(function(res){
        vm.databases = _.map(res, function(database){
          database.urlExcel = $globalService.salesReportExcel({id: database.id, token: vm.currentUser.token});
          return database;
        });
        vm.loading = false;
      })
    }

    vm.show = function(id){
      vm.loading = true;
      $globalService.salesReport.get({id: id}).$promise
      .then(function(res){
        vm.currentDatabase = res;
        vm.currentDatabase.urlExcel = $globalService.salesReportExcel({id: vm.currentDatabase.id, token: vm.currentUser.token});
        vm.loading = false;
      })
    }

    vm.destroyDatabase = function(database, index) {
      vm.loading = true;
      Swal({
        title: 'Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.salesReport.remove({id: database.id}).$promise
            .then(function(res){
              vm.databases.splice(index, 1)
              vm.currentDatabase = undefined;
              resolve(res.message);
            }, function(err){
              Swal('Error',err.data.message,'error');
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
        allowEscapeKey: false
      });
    }


    function add(){
      var modal = $uibModal.open({
        animation: true,
        component: 'salesModalCheck',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          new_file: function(){
            return false
          }
        }
      });

      modal.result.then(function(res){
        Swal('Success',res,'success');
        vm.$onInit();
      }, function(err){
        Swal('Error',err,'error');
      });
    }
    
  }
})();
