(function(){
  'use strict';
  angular
  .module('app')
  .component('reportCalls', {
    templateUrl: 'app/components/report-calls/report-calls.html',
    controller: ReportCallsController
  });

  ReportCallsController.$inject = ['user', '$rootScope', '$interval', '_', 'globalService','$uibModal','url'];

  function ReportCallsController($user, $rootScope, $interval, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.loadingData = false;
    vm.date = new Date();

    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Applications', before: 'Home'});
      $globalService.getAlloperators()
      .then(function(res){
        vm.operators = res;
      })
      
    }


    vm.consultCustomers = function(){
      var data = {
        date: moment(vm.date).format('YYYY-MM-DD'),
        user_id: vm.userSelected ? vm.userSelected.id : null,
      }
      $globalService.reportNumbers(data)
      .then(function(res){
        vm.customers = res;
        vm.urlReport = $globalService.reportPdf('reports_numbers_users',data);
      });
    }
  }


})();
