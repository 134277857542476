angular
  .module('app', ['ui.router',
                  'slick',
                  'ngAnimate',
                  'ngResource',
                  'ngTouch',
                  'ngSanitize',
                  'ui.bootstrap',
                  'toastr',
                  'ngActionCable',
                  'ui.select',
                  'ngFileUpload']);
