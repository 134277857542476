angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider,$httpProvider) {
  $httpProvider.interceptors.push(['$q', 'user', '$rootScope','$state','$window', function ($q, user, $rootScope,$state, $window) {
      return {
        request: function(config) {
          // config.headers['content-type'] = 'application/json';
          if(user.getCurrentUser()){
            config.headers['Authorization'] = user.getCurrentUser().token;
          }

          return config;
        },
        responseError: function(rejection) {
          if (rejection.status == 401) {
            $rootScope.$broadcast('UNAUTHORIZED');
          }

          return $q.reject(rejection);
        }
      };
    }]);
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
  .state('webLayout', {
    abstract: true,
    component: 'webLayout'
  })
  .state('loginLayout', {
    abstract: true,
    component: 'loginLayout'
  })
  .state('login', {
    parent: 'loginLayout',
    url: '/login',
    component: 'auth',
  })
  .state('signup', {
    parent: 'loginLayout',
    url: '/registrate',
    component: 'signUp',
  })
  .state('blockScreen', {
    parent: 'loginLayout',
    url: '/inactividad-detectada?current_page',
    component: 'blockScreen',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('home', {
    parent: 'webLayout',
    url: '/',
    component: 'home',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('users', {
    parent: 'webLayout',
    url: '/administracion-de-usuarios',
    component: 'users',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.users.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('databases', {
    parent: 'webLayout',
    url: '/databases',
    component: 'databases',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.upload_files.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('databaseReports', {
    parent: 'webLayout',
    url: '/databses-reports',
    component: 'databaseReports',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.reports_calls.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('databaseCheckReport', {
    parent: 'webLayout',
    url: '/databses-check-reports',
    component: 'databaseCheckReport',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.reports_calls.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('reportCalls', {
    parent: 'webLayout',
    url: '/reports-calls',
    component: 'reportCalls',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.reports_calls.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('checkSalesReport', {
    parent: 'webLayout',
    url: '/reports/check-sales',
    component: 'checkSalesReport',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.reports_calls.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  
  ;

}
