(function () {
  'use strict';
  angular
  .module('app')
  .component('salesModalCheck', {
    templateUrl: 'app/components/salesReport/modalUploadSales.html',
    controller: ModalUploadSalesReportontroller,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });


  ModalUploadSalesReportontroller.$inject = ['url','user', 'Upload', '$interval', '_','globalService','$rootScope'];

  function ModalUploadSalesReportontroller($url, $user, Upload, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.currentUser = $user.getCurrentUser();
    vm.new_file = true;
    
    vm.$onInit = function(){
      vm.new_file = vm.resolve.new_file;
    }
    function save(){
      if(Upload.isFile(vm.database.file)){
        vm.database.file.upload = Upload.upload({
          url: $url.getApiUrl('check_databases_sales'),
          data: vm.database,
        })
        vm.database.file.upload.then(function(res){
          vm.send = false;
          vm.close({$value: res.data.message});
        }, function(err){
          vm.dismiss({$value: res.data.message});
          vm.send = false;
        }, function (evt) {
          vm.database.file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
      
      }else{
        Swal('Info','Not a file','warning');
      }
    }
  }


})();


