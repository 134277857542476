(function(){
  'use strict';
  angular
  .module('app')
  .component('applications', {
    templateUrl: 'app/components/applications/applications.html',
    controller: ApplicationController
  });

  ApplicationController.$inject = ['user', '$uibModal', 'globalService', '_','$state','$rootScope', '$interval'];

  function ApplicationController($user, $uibModal, $globalService, _, $state, $rootScope, $interval) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.step1 = true;
    vm.selectType = selectType;
    vm.message = "Select the type of service";
    vm.mainApplicant = mainApplicant;
    vm.sexs = ["Masculino","Femenino"];
    vm.status_migratoriess = ["CITIZEN", "PERMANENT RESIDENT", "WORK PERMIT", "VISA", "FAROLEE", "OTHERS"];
    vm.work_types = ["employee", "self employee"];
    vm.civil_statuses = ['Casado','Soltero'];
    vm.readyToApply = false;
    vm.showMain = true;
    vm.showSpose = false;
    vm.showDependents = false;
    vm.showPayment = false;
    vm.loadingApp = false;
    vm.addDependents = addDependents;
    vm.spouseApplicant = spouseApplicant;
    vm.dependentApplicant = dependentApplicant;
    vm.finishApplication = finishApplication;
    vm.setTypeOfCard = setTypeOfCard;
    vm.card_type_icon = 'fa-credit-card-alt';
    vm.validateDate = validateDate;
    vm.validateNumberPhone = validateNumberPhone;
    vm.rejectPhoneNumber = /^\+?\d{3}[- ]?\d{3}[- ]?\d{4}$/;


    vm.$onInit = function(){
      vm.application = {main_applicant: {immigration_number: {0: 'A'}},spouse_applicant: {immigration_number: {0: 'A'}}};
      var load = 0;
      $globalService.type_insurance_companies()
      .then(function(res){
        vm.type_insurance_companies = res;
        load = load + 1;
      })
      $globalService.get_states()
      .then(function(res){
        vm.states = res;
        load = load + 1;
      })
      $globalService.get_application_types()
      .then(function(res){
        vm.applicationsType = res;
        load = load + 1;
      })
      if($state.params.id){
        var customer_application = $globalService.getLocalCA($state.params.id);       
        customer_application.main_applicant.birthday = new Date(moment(customer_application.main_applicant.birthday, 'MM-DD-YYYY').format('MM-DD-YYYY'));
        
        if(customer_application.hasOwnProperty('spouse') && customer_application.spouse != null){
          customer_application.spouse.birthday = new Date(moment(customer_application.spouse.birthday, 'MM-DD-YYYY').format('MM-DD-YYYY'));
        }
        if(customer_application.hasOwnProperty('dependents')){
          _.map(customer_application.dependents, function(dependent){
            dependent.birthday = new Date(moment(dependent.birthday, 'MM-DD-YYYY').format('MM-DD-YYYY'));
            return dependent;
          })
        }
        if(customer_application)
        vm.application = angular.copy(customer_application);
        
        var stop;

        stop = $interval(function(){
          console.log(load)
          if(load == 3){
            selectType(_.find(vm.type_insurance_companies, {id: vm.application.insurance_data.company_product_type.id}))
            $interval.cancel(stop);
            stop = undefined;
          }
        },100)
      }

    }

    function selectType(data){
      vm.step1 = false;
      var modal = $uibModal.open({
        animation: true,
        component: 'showDetails',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          type_insurance_companies: function(){
            return data;
          },
          allCompanies: function(){
            return getAllCompanies();
          },
          applicationsType: function(){
            return vm.applicationsType;
          },
          application: function(){
            return vm.application;
          }
        }
      });

      modal.result.then(function(res){
        vm.readyToApply = true;
        vm.application = res;
        if(res.id){
          if(vm.application.main_applicant.hasOwnProperty('card_number') && typeof(vm.application.main_applicant.card_number) == "string"){
            var card_number = vm.application.main_applicant.card_number.split('-');
            vm.application.main_applicant.card_number = {
              0: card_number[0],
              1: card_number[1]
            }
            
          }
          if(vm.application.main_applicant.hasOwnProperty('card_expiration_date')){
            var date = new Date(moment(angular.copy(vm.application.main_applicant.card_expiration_date), 'MM-DD-YYYY').format('MM/DD/YYYY'));
            
            vm.application.main_applicant.card_expiration_date = date;
            
          }
          if(vm.application.main_applicant.hasOwnProperty('social_security_number') && typeof(vm.application.main_applicant.social_security_number) == "string"){
            var social_security_number = vm.application.main_applicant.social_security_number.split('-');
            vm.application.main_applicant.social_security_number = {
              0: social_security_number[0],
              1: social_security_number[1],
              2: social_security_number[2],
            }
          }
          if(vm.application.main_applicant.hasOwnProperty('immigration_number') && typeof(vm.application.main_applicant.immigration_number) == "string"){
            var inmigration_number = vm.application.main_applicant.immigration_number.split('-');
            vm.application.main_applicant.immigration_number = {
              0: inmigration_number[0],
              1: inmigration_number[1],
              2: inmigration_number[2],
              3: inmigration_number[3]
            }
          }
        }else{
          vm.application.main_applicant = {immigration_number: {0: 'A'} };
          vm.application.spouse_applicant = {immigration_number: {0: 'A'} };
          vm.application.dependents = [];
        }
      }, function(err){
        vm.readyToApply = false;
        vm.step1 = true;
      });

    }

    function getAllCompanies(){
      vm.allCompanies = _.reduce(vm.type_insurance_companies, function(memo, data){
        _.forEach(data.companies, function(company){
          memo.push(company)
        })
        return memo;
      },[])

      return vm.allCompanies;
    }

    function mainApplicant(){
      vm.showMain = false;
      vm.showSpose = true
      if(vm.application.id){
        if(vm.application.hasOwnProperty('spouse') && vm.application.spouse != null){
          var spouse_applicant = vm.application.spouse;

          if(spouse_applicant.hasOwnProperty('card_number')){
            var card_number = spouse_applicant.card_number.split('-');
            spouse_applicant.card_number = {
              0: card_number[0],
              1: card_number[1]
            }
            
          }
          if(spouse_applicant.hasOwnProperty('card_expiration_date')){
            spouse_applicant.card_expiration_date = new Date(moment(spouse_applicant.card_expiration_date, 'MM/DD/YYYY').format('MM-DD-YYYY'));
          }
          if(spouse_applicant.hasOwnProperty('social_security_number')){
            var social_security_number = spouse_applicant.social_security_number.split('-');
            spouse_applicant.social_security_number = {
              0: social_security_number[0],
              1: social_security_number[1],
              2: social_security_number[2]
            }
          }
          if(spouse_applicant.hasOwnProperty('immigration_number')){
            var inmigration_number = spouse_applicant.immigration_number.split('-');
            spouse_applicant.immigration_number = {
              0: inmigration_number[0],
              1: inmigration_number[1],
              2: inmigration_number[2],
              3: inmigration_number[3]
            }
          }
          vm.application.spouse_applicant = angular.copy(spouse_applicant);
        }
        console.log(vm.application.main_applicant)
      }
      if(vm.application.main_applicant.birthday){
        vm.application.main_applicant.birthday = moment(vm.application.main_applicant.birthday).format('MM/DD/YYYY');
      }
      if(vm.application.main_applicant.status_migratory == 'CITIZEN'){
        delete vm.application.main_applicant.immigration_number;
      }else{
        vm.application.main_applicant.immigration_number = Object.values(vm.application.main_applicant.immigration_number).join('-');
        vm.application.main_applicant.card_number = Object.values(vm.application.main_applicant.card_number).join('-');
      }
      if(vm.application.main_applicant.social_security_number){
        vm.application.main_applicant.social_security_number = Object.values(vm.application.main_applicant.social_security_number).join('-');
      }
      if(vm.application.main_applicant.card_expiration_date){
        vm.application.main_applicant.card_expiration_date = moment(vm.application.main_applicant.card_expiration_date).format('MM-DD-YYYY');
      }
      
    }

    function addDependents(){
      if(!vm.application.hasOwnProperty('dependents')){
        vm.application = {dependents: []}
      }
      vm.application.dependents.push({
        name: '',
        last_name: '',
        birthday: '',
        social_security_number: {

        },
        status_migratory: '',
        immigration_number: {
          0: 'A'
        },
        card_number: {},
        card_expiration_date: '',
        category: '',
      })
    }

    function spouseApplicant(){
      if(vm.application.spouse_applicant.birthday){
        vm.application.spouse_applicant.birthday = moment(vm.application.spouse_applicant.birthday).format('MM/DD/YYYY');
      }
      if(vm.application.spouse_applicant.status_migratory == 'CITIZEN'){
        delete vm.application.spouse_applicant.immigration_number;
      }else{
        vm.application.spouse_applicant.immigration_number = Object.values(vm.application.spouse_applicant.immigration_number).join('-');
        vm.application.spouse_applicant.card_number = Object.values(vm.application.spouse_applicant.card_number).join('-');
      }
      if(vm.application.spouse_applicant.social_security_number){
        vm.application.spouse_applicant.social_security_number = Object.values(vm.application.spouse_applicant.social_security_number).join('-');
      }
      if(vm.application.spouse_applicant.card_expiration_date){
        vm.application.spouse_applicant.card_expiration_date = moment(vm.application.spouse_applicant.card_expiration_date).format('MM/DD/YYYY');
      }

      if(vm.application.hasOwnProperty('dependents') && vm.application.dependents != null){
        _.forEach(vm.application.dependents, function(dependent){
          if(dependent.hasOwnProperty('social_security_number') && typeof(dependent.social_security_number) == "string"){
            var ssn = dependent.social_security_number.split('-');
            dependent.social_security_number = {
              0: ssn[0],
              1: ssn[1],
              2: ssn[2]
            }
          }
          if(dependent.hasOwnProperty('immigration_number') && typeof(dependent.immigration_number) == "string"){
            var immigration_number = dependent.immigration_number.split('-');
            dependent.immigration_number = {
              0: immigration_number[0],
              1: immigration_number[1],
              2: immigration_number[2],
              3: immigration_number[3]
            }
          }
          if(dependent.hasOwnProperty('card_number') && typeof(dependent.card_number) == "string"){
            var card_number = dependent.card_number.split('-');
            dependent.card_number = {
              0: card_number[0],
              1: card_number[1],
              2: card_number[2],
              3: card_number[3]
            }
          }
        })
      }

      vm.showSpose = false;
      vm.showDependents = true;
    }

    function dependentApplicant(){
    
      if(vm.application.dependents.length > 0){
        _.map(vm.application.dependents, function(dependent){
          if(dependent.birthday){
            dependent.birthday = moment(dependent.birthday).format('MM/DD/YYYY');
          }
          if(dependent.status_migratory == 'CITIZEN'){
            delete dependent.immigration_number;
            delete dependent.card_number;
          }else{
            dependent.immigration_number = Object.values(dependent.immigration_number).join('-');
            dependent.card_number = Object.values(dependent.card_number).join('-');
          }
          if(dependent.social_security_number){
            dependent.social_security_number = Object.values(dependent.social_security_number).join('-');
          }
          if(dependent.card_expiration_date){
            dependent.card_expiration_date = moment(dependent.card_expiration_date).format('MM/DD/YYYY');
          }
          return dependent;
        })

      }

      if(vm.application.hasOwnProperty('billing_information') && vm.application.billing_information != null){
        var billing = vm.application.billing_information;
        if(billing.hasOwnProperty('expiration_card')){
          billing.expiration_card = new Date(moment(billing.expiration_card, 'MM-YYYY').format('MM-DD-YYYY'));
          setTypeOfCard(billing.card_number)
        }
      }
      vm.application.billing = angular.copy(billing);

      vm.showDependents = false;
      vm.showPayment = true;

    }

    function finishApplication(){
      vm.loadingApp = true;
      if(vm.application.billing.expiration_card){
        vm.application.billing.expiration_card = moment(vm.application.billing.expiration_card).format('MM-YYYY')
      }
      if(vm.application.id){
        swal({
          title: 'Indique que datos aplicacion en el cambio de vida',
          input: 'textarea',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function(reason) {
            vm.application.reason = reason;
            return $globalService.sendApplicationChangeLife(vm.application)
            .then(function(res){
              vm.send = false;
              vm.loadingApp = false;
              Swal('Exito',res.message,'success');
              switch (vm.currentUser.pretty_type) {
                case "Manager":
                $state.go('dashboardManager');
                  break;
                case "Administrator":
                  $state.go('home');
                  break;
                case "Agente":
                  $state.go('dashboardAgent');
                  break
                default:
                  $state.go('home');
              }
            }, function(err){
              vm.loadingApp = false;
              swal.showValidationError(err.data.message);
            });
          }
        })
      }else{
        $globalService.sendApplication(vm.application)
        .then(function(res){
          vm.send = false;
          vm.loadingApp = false;
          Swal('Exito',res.message,'success');
          switch (vm.currentUser.pretty_type) {
            case "Manager":
            $state.go('dashboardManager');
              break;
            case "Administrator":
              $state.go('home');
              break;
            case "Agente":
              $state.go('dashboardAgent');
              break
            default:
              $state.go('home');
          }
        }, function(err){
          vm.loadingApp = false;
          Swal('Error',err.data.message,'warning');
        });
      }

    }

    function setTypeOfCard(data){
      var card_icon = validatioRejec(data);
      if(card_icon){
        vm.card_type_icon = card_icon;
      }
    }

    function validatioRejec(number){
      var re = {
        'electron': /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        'maestro': /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        'dankort': /^(5019)\d+$/,
        'interpayment': /^(636)\d+$/,
        'unionpay': /^(62|88)\d+$/,
        'fa-cc-visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
        'fa-cc-mastercard': /^5[1-5][0-9]{14}$/,
        'fa-cc-amex': /^3[47][0-9]{13}$/,
        'fa-cc-diners-club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        'fa-cc-discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        'fa-cc-jcb': /^(?:2131|1800|35\d{3})\d{11}$/
      }

      for(var key in re) {
        if(re[key].test(number)) {
          return key
        }
      }
    }

    function validateNumberPhone(number){
      console.log('asdasdsa')
      var reject = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
      console.log(reject.test(number))
      return reject.test(number)
    }

    function validateDate(){
      if(moment(vm.application.billing.expiration_card) < moment()){
        Swal('Warning','Invalid Date: Expired Card','warning');
      }
    }

    vm.checkCardExpirationDate = function(data){
      if(moment(data) < moment()){
        Swal('Warning','Invalid Date: Expired Card','warning');
      }
    }

    vm.skipSpouse = function(){
      if(vm.application.main_applicant.civil_status == 'Casado'){
        Swal('Stop','Complete a spouse data','info');
        return
      }
      vm.showSpose = false;
      vm.showDependents = true;
      delete vm.application.spouse_applicant;
    }
    vm.skipDependents = function(){
      vm.showDependents = false;
      vm.showPayment = true;
      delete vm.application.dependents;

      if(vm.application.hasOwnProperty('billing_information') && vm.application.billing_information != null){
        var billing = vm.application.billing_information;
        if(billing.hasOwnProperty('expiration_card')){
          billing.expiration_card = new Date(moment(billing.expiration_card, 'MM-YYYY').format('MM-DD-YYYY'));
        }
      }
      vm.application.billing = angular.copy(billing);
    }

    vm.setSpouseStatus = function(){
      if(vm.application.main_applicant.civil_status == 'Casado'){
        vm.application.spouse_applicant.civil_status = 'Casado';
      }
    }
    vm.removeDependent = function(data, index){
      vm.application.dependents.splice(index, 1);
    }

    vm.setAccountHolder = function(){
      vm.application.billing = {
        account_holder: vm.application.main_applicant.name + ' ' + vm.application.main_applicant.last_name,
      }
    }

    vm.backToMain = function(){
      if(vm.application.main_applicant.hasOwnProperty('card_number') && typeof(vm.application.main_applicant.card_number) == "string"){
        var card_number = vm.application.main_applicant.card_number.split('-');
        vm.application.main_applicant.card_number = {
          0: card_number[0],
          1: card_number[1]
        }
        
      }
      if(vm.application.main_applicant.hasOwnProperty('card_expiration_date')){
        var date = new Date(moment(angular.copy(vm.application.main_applicant.card_expiration_date), 'MM-DD-YYYY').format('MM/DD/YYYY'));
        
        vm.application.main_applicant.card_expiration_date = date;
        
      }
      if(vm.application.main_applicant.hasOwnProperty('social_security_number') && typeof(vm.application.main_applicant.social_security_number) == "string"){
        var social_security_number = vm.application.main_applicant.social_security_number.split('-');
        vm.application.main_applicant.social_security_number = {
          0: social_security_number[0],
          1: social_security_number[1],
          2: social_security_number[2],
        }
      }
      if(vm.application.main_applicant.hasOwnProperty('immigration_number') && typeof(vm.application.main_applicant.immigration_number) == "string"){
        var inmigration_number = vm.application.main_applicant.immigration_number.split('-');
        vm.application.main_applicant.immigration_number = {
          0: inmigration_number[0],
          1: inmigration_number[1],
          2: inmigration_number[2],
          3: inmigration_number[3]
        }
      }
      vm.showSpose = false;
      vm.showMain = true;
    }

    vm.backToSpouse = function(){
      if(vm.application.hasOwnProperty('spouse_applicant')){
        if(vm.application.spouse_applicant.hasOwnProperty('card_number')){
          var card_number = vm.application.spouse_applicant.card_number.split('-');
          vm.application.spouse_applicant.card_number = {
            0: card_number[0],
            1: card_number[1]
          }
          
        }
        if(vm.application.spouse_applicant.hasOwnProperty('social_security_number')){
          var social_security_number = vm.application.spouse_applicant.social_security_number.split('-');
          vm.application.spouse_applicant.social_security_number = {
            0: social_security_number[0],
            1: social_security_number[1],
            2: social_security_number[2]
          }
        }
        if(vm.application.spouse_applicant.hasOwnProperty('immigration_number')){
          var inmigration_number = vm.application.spouse_applicant.immigration_number.split('-');
          vm.application.spouse_applicant.immigration_number = {
            0: inmigration_number[0],
            1: inmigration_number[1],
            2: inmigration_number[2],
            3: inmigration_number[3]
          }
        }
      }
      vm.showDependents = false;
      vm.showSpose = true;
    }

    vm.backToDependents = function(){
      if(vm.application.dependents.length > 0){
        _.forEach(vm.application.dependents, function(dependent){
          if(dependent.hasOwnProperty('social_security_number') && typeof(dependent.social_security_number) == "string"){
            var ssn = dependent.social_security_number.split('-');
            dependent.social_security_number = {
              0: ssn[0],
              1: ssn[1],
              2: ssn[2]
            }
          }
          if(dependent.hasOwnProperty('immigration_number') && typeof(dependent.immigration_number) == "string"){
            var immigration_number = dependent.immigration_number.split('-');
            dependent.immigration_number = {
              0: immigration_number[0],
              1: immigration_number[1],
              2: immigration_number[2],
              3: immigration_number[3]
            }
          }
          if(dependent.hasOwnProperty('card_number') && typeof(dependent.card_number) == "string"){
            var card_number = dependent.card_number.split('-');
            dependent.card_number = {
              0: card_number[0],
              1: card_number[1],
              2: card_number[2],
              3: card_number[3]
            }
          }
        })
      }
      vm.showPayment = false;
      vm.showDependents = true;
    }

  }


})();
