(function () {
  'use strict';
  angular
  .module('app')
  .component('userModal', {
    templateUrl: 'app/components/users/user_modal.html',
    controller: UserModalController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  UserModalController.$inject = ['$sce','globalService'];

  function UserModalController($sce, $globalService) {
    var vm = this;
    vm.save = save;
    vm.showFirsTab = true;
    vm.showSecondTab = false;
    vm.setUserPermissions = setUserPermissions;
    vm.confirmPermissions = confirmPermissions;




    vm.$onInit = function() {
      vm.sections = _.map(vm.resolve.sections, function(data){
        data.selected = false;
        return data;
      });
      vm.user_types = vm.resolve.user_types;
      vm.user = vm.resolve.user;
      if(vm.user){
        console.log(vm.user)
        vm.typeSelected = _.find(vm.user_types, {id: vm.user.user_type_id});
        _.forEach(vm.sections, function(data){
          if(vm.user.permits_granted[data.name] && vm.user.permits_granted[data.name].active){
            data.selected = true;
          }
        })
      }
      console.log(vm.sections)

    }

    function confirmPermissions(){
      vm.user.user_type_id = vm.typeSelected.id;
      vm.showSecondTab = true;
      vm.showFirsTab = false;
    }


    function save(){
      vm.user.permissions = _.reduce(vm.sections, function(memo, data){
        if(data.selected){
          memo.push({
            active: true,
            section_id: data.id
          })
        }
        return memo;
      },[])
      if(vm.user.id){
        $globalService.users.update({id: vm.user.id}, vm.user, function(res){
          vm.send = false;
          Swal('Exito',res.message,'success');
          vm.close({$value: res.data});
        }, function(err){
          Swal('Error',res.message,'error');
          vm.cancel({$value: ''});
        })
      }else{
        $globalService.users.save(vm.user,
          function(res){
            vm.send = false;
            Swal('Exito',res.message,'success');
            vm.close({$value: res.data})
          }, function(err){
            Swal('Error',err.data.message,'warning');
            vm.cancel({$value: ''});
          });
      }

    }

    function setUserPermissions(){
      vm.user.user_type_id = vm.typeSelected.id;
      _.forEach(vm.sections, function(data){
        data.selected = false;
      })
      _.forEach(_.filter(vm.sections, {type_section: vm.typeSelected.permission_type}), function(data){
        console.log(data);
        data.selected = true;
      })
    }

  }

})();
